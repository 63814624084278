<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import {Head, Link, useForm, usePage} from '@inertiajs/vue3';
import {route} from "@/Composables/route.js";
import {trans} from "../Composables/trans.js";

const page = usePage();

const onboardingForm = useForm({
    channel_link: null
});

const submitOnboardingForm = () => {
    onboardingForm.post(route('channels.onboarding'));
};
</script>

<template>
    <AppLayout>
        <Head>
            <title>{{ trans('meta.index.title') }}</title>
            <meta
                head-key="description"
                name="description"
                :content="trans('meta.index.description')"
            />
        </Head>
        <section class="container py-lg-8 py-5">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10 col-12">
                    <div class="text-center d-flex flex-column gap-5">
                        <div class="d-flex justify-content-center">
                            <Link
                                :href="route('plans')"
                                class="text-bg-danger p-2 fs-6 rounded-pill lh-1 d-flex align-items-center"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <span class="badge text-bg-dark">
                                    {{ trans('temporarily.wow') }}
                                </span>
                                <span class="mx-2">
                                    {{ trans('temporarily.new_year_sale_stated') }}
                                </span>
                            </Link>
                        </div>
                        <div class="d-flex flex-column gap-3 mx-lg-8">
                            <h1 class="mb-0 display-4">
                                {{ trans('index.index.enhance_popularity') }}
                            </h1>
                            <p class="mb-0 lead">
                                <span>
                                    {{ trans('index.index.grow_your_stream', {app_name: page.props.app.name}) }}
                                    <span class="badge rounded-pill text-bg-dark">
                                        {{ trans('index.index.free_viewers') }}
                                    </span>
                                    {{ trans('index.index.for_a_month') }}
                                </span>
                                <img
                                    class="ms-1"
                                    width="20px"
                                    src="/assets/images/animated/gift.gif"
                                    alt="Gift Emoji"
                                />
                            </p>
                        </div>
                        <form class="row g-2 d-flex mx-lg-7" @submit.prevent="submitOnboardingForm">
                            <div class="col-lg-8 col-xxl-9 col-12">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="twitch.tv/channel"
                                    v-model="onboardingForm.channel_link"
                                    required
                                >
                            </div>
                            <div class="col-lg-4 col-xxl-3 col-12">
                                <div class="d-grid">
                                    <button class="btn btn-primary btn-lg" type="submit">
                                        <span>
                                            {{ trans('index.index.boost') }}
                                        </span>
                                        <img
                                            class="ms-1"
                                            width="20px"
                                            src="/assets/images/animated/thunder.gif"
                                            alt="Thunder Emoji"
                                        />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5">
            <div class="container">
                <div class="row border-top border-bottom">
                    <div class="col-lg-4 border-end-lg border-bottom border-bottom-lg-0">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-trophy-fill display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.quality') }}</h4>
                                <p class="mb-0">{{ trans('index.index.quality_description') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 border-end-lg border-bottom border-bottom-lg-0">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-heart-fill display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.support') }}</h4>
                                <p class="mb-0">{{ trans('index.index.support_description') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-person-fill-check display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.stability') }}</h4>
                                <p class="mb-0">{{ trans('index.index.stability_description') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row border-bottom">
                    <div class="col-lg-4 border-end-lg border-bottom border-bottom-lg-0">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-shield-fill-check display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.security') }}</h4>
                                <p class="mb-0">{{ trans('index.index.security_description') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 border-end-lg border-bottom border-bottom-lg-0">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-percent display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.discounts') }}</h4>
                                <p class="mb-0">{{ trans('index.index.discounts_description') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-stack display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.stream_analysis') }}</h4>
                                <p class="mb-0">{{ trans('index.index.stream_analysis_description') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
                        <div class="text-center mb-7">
                            <h2>{{ trans('index.index.faq_title') }}</h2>
                            <p class="mb-0">
                                {{ trans('index.index.faq_intro') }}
                                <br/>
                                <Link :href="route('support')" class="text-primary">
                                    {{ trans('index.index.ask_support') }}
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
                        <div class="accordion">
                            <div class="border mb-2 rounded-3 p-3">
                                <h2 class="h5 mb-0">
                                    <a href="#"
                                       class="text-reset d-flex justify-content-between align-items-center"
                                       data-bs-toggle="collapse"
                                       data-bs-target="#collapseOne">
                                        {{ trans('index.index.faq.trial_service') }}
                                        <span class="chevron-arrow"><i class="bi bi-chevron-down"/></span>
                                    </a>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse">
                                    <div class="mt-3">
                                        {{ trans('index.index.faq.trial_answer') }}
                                    </div>
                                </div>
                            </div>
                            <div class="border mb-2 rounded-3 p-3">
                                <h2 class="h5 mb-0">
                                    <a href="#"
                                       class="text-reset d-flex justify-content-between align-items-center"
                                       data-bs-toggle="collapse"
                                       data-bs-target="#collapseTwo">
                                        {{ trans('index.index.faq.bots_question') }}
                                        <span class="chevron-arrow"><i class="bi bi-chevron-down"/></span>
                                    </a>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse">
                                    <div class="mt-3">
                                        {{ trans('index.index.faq.bots_answer') }}
                                    </div>
                                </div>
                            </div>
                            <div class="border mb-2 rounded-3 p-3">
                                <h2 class="h5 mb-0">
                                    <a href="#"
                                       class="text-reset d-flex justify-content-between align-items-center"
                                       data-bs-toggle="collapse"
                                       data-bs-target="#collapseFour">
                                        {{ trans('index.index.faq.change_plan_question') }}
                                        <span class="chevron-arrow"><i class="bi bi-chevron-down"/></span>
                                    </a>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse">
                                    <div class="mt-3">
                                        {{ trans('index.index.faq.change_plan_answer') }}
                                    </div>
                                </div>
                            </div>
                            <div class="border mb-2 rounded-3 p-3">
                                <h2 class="h5 mb-0">
                                    <a href="#"
                                       class="text-reset d-flex justify-content-between align-items-center"
                                       data-bs-toggle="collapse"
                                       data-bs-target="#collapseFive">
                                        {{ trans('index.index.faq.plan_choice_question') }}
                                        <span class="chevron-arrow"><i class="bi bi-chevron-down"/></span>
                                    </a>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse">
                                    <div class="mt-3">
                                        {{ trans('index.index.faq.plan_choice_answer') }}
                                    </div>
                                </div>
                            </div>
                            <div class="border mb-2 rounded-3 p-3">
                                <h2 class="h5 mb-0">
                                    <a href="#"
                                       class="text-reset d-flex justify-content-between align-items-center"
                                       data-bs-toggle="collapse"
                                       data-bs-target="#collapseThree">
                                        {{ trans('index.index.faq.compensation_question') }}
                                        <span class="chevron-arrow"><i class="bi bi-chevron-down"/></span>
                                    </a>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse">
                                    <div class="mt-3">
                                        {{ trans('index.index.faq.compensation_answer') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </AppLayout>
</template>
